<template>
      <div class="page-unavailable">
      <h2>Сторінка наразі недоступна</h2>
      <p>Залиште свої покупки в кошику. Ви зможете їх оплатити, щойно сторінка стане доступною.</p>
    </div>
  <div class="checkout-page _container">
    <div class="content-blurred">
    <div class="form_page">
      <!-- Форма чекауту -->
      <div v-if="currentStep === 1">
        <h3>Персональна інформація</h3>
        <form @submit.prevent="goToStep(2)">
          <div class="form-group">
            <label for="first-name">Ім'я</label>
            <input type="text" v-model="personalInfo.firstName" required />
          </div>
          <div class="form-group">
            <label for="last-name">Прізвище</label>
            <input type="text" v-model="personalInfo.lastName" required />
          </div>
          <div class="form-group">
            <label for="phone">Номер телефону</label>
            <input type="tel" v-model="personalInfo.phone" required />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" v-model="personalInfo.email" required />
          </div>
          <button type="submit">Далі</button>
        </form>
      </div>

      <!-- Вибір доставки -->
      <div v-if="currentStep === 2">
        <h3>Інформація про доставку</h3>
        <div class="delivery-methods">
          <button :class="{'active-button': deliveryMethod === 'nova-poshta'}" @click="selectDeliveryMethod('nova-poshta')">Нова пошта</button>
          <button :class="{'active-button': deliveryMethod === 'courier'}" @click="selectDeliveryMethod('courier')">Кур'єр</button>
          <button :class="{'active-button': deliveryMethod === 'pickup'}" @click="selectDeliveryMethod('pickup')">Самовивіз</button>
        </div>

        <div v-if="deliveryMethod === 'nova-poshta'">
          <div class="form-group">
            <label for="city">Населений пункт</label>
            <input type="text" v-model="deliveryInfo.city" required />
          </div>
          <div class="form-group">
            <label for="branch">Відділення</label>
            <input type="text" v-model="deliveryInfo.department" required />
          </div>
          <div class="form-group">
            <label>Тип оплати</label>
            <div class="payment-methods">
              <button :class="{'active-button': paymentType === 'full'}" @click="selectPaymentType('full')">Повна оплата</button>
              <button :class="{'active-button': paymentType === 'prepayment'}" @click="selectPaymentType('prepayment')">Передоплата 200 грн</button>
            </div>
            <div v-if="paymentType === 'full'">
              <button @click.prevent="handlePayment">Оплатити</button>
            </div>
            <div v-if="paymentType === 'prepayment'">
              <button @click.prevent="handlePayment">Оплатити</button>
            </div>
          </div>
        </div>

        <div v-if="deliveryMethod === 'courier'">
          <div class="form-group">
            <label for="street">Вулиця</label>
            <input type="text" v-model="deliveryInfo.street" required />
          </div>
          <div class="form-group">
            <label for="house">Будинок</label>
            <input type="text" v-model="deliveryInfo.house" required />
          </div>
          <div class="form-group">
            <label for="apartment">Квартира</label>
            <input type="text" v-model="deliveryInfo.apartment" required />
          </div>
          <div class="form-group">
            <label>Оплата</label>
            <div class="payment-methods">
              <button :class="{'active-button': paymentType === 'cash'}" @click="selectPaymentType('cash')">Готівкою при отриманні</button>
              <button :class="{'active-button': paymentType === 'full'}" @click="selectPaymentType('full')">Повна оплата по карті</button>
            </div>
          </div>
           <div v-if="paymentType === 'full'">
              <button @click.prevent="handlePayment">Оплатити</button>
           </div>
           <div v-if="paymentType === 'cash'">
              <form @submit.prevent="submitOrder">
                <p>Оплата замовлення при отримані від Кур'єра</p>
                <button type="submit">Замовити</button>
              </form>
           </div>
        </div>

        <div v-if="deliveryMethod === 'pickup'">
          <form @submit.prevent="submitOrder">
            <p>Cамовивіз з м. Київ, вулиця Григорія Кочура, 16а.</p>
            <button type="submit">Замовити</button>
          </form>
        </div>
      </div>

      <!-- Очікування оплати -->
      <div class='spinner_block' v-if="currentStep === 3">
        <h3>Очікуємо підтвердження оплати...</h3>
        <p>Платіж обробляється, зачекайте декілька секунд.</p>
        <div class="loading-spinner"></div>
      </div>
    </div>

    <div class="cart_page">
      <div class="cart_items">
        <div class="cart_item" v-for="(item, index) in cartItems" :key="index">
          <div class="cart_item_img">
            <img :src="item.image" :alt="item.name">
          </div>
          <div class="cart_item_details">
            <div class="cart_item_title">{{ item.name }}</div>
            <div class="cart_item_price">{{ item.price }} грн.</div>
          </div>

          <div class="cart_item_qty">
            <div class="qty_wrapper">
              <button type="button" class="qty_adjust" @click="addOrRemove(item, -1)">−</button>
              <input type="number" v-model="item.qty" class="qty_input" @input="updateQuantity(item)" min="1">
              <button type="button" class="qty_adjust" @click="addOrRemove(item, 1)">+</button>
            </div>
          </div>

          <div class="cart_item_delete">
            <button type="button" class="delete_button" @click="removeFromCart(item)">
              <img :src="delete_button" alt="Delete">
            </button>
          </div>
        </div>
      </div>
      <div class="cart_info_block">
        <div class="discount_prod">
          <form @submit.prevent="applyPromoCode" class="form-group">
            <input type="text" v-model="promoCode" placeholder="Введіть промокод">
            <button type='submit' class='apple_promo'>Застосувати</button>
          </form>
          <p v-if="error">{{ error }}</p>
        </div>
        <div class="cart_total_block">
          <div class="total_proce">
            <p v-if="discount">Знижка за промокодом: {{ discount }} %.</p>
            <p>Доставка:{{ deliveryFee }}</p> 
            <p>До сплати: {{  cartTotal }} грн.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import delete_button from '@/assets/img/ico/delete-button.svg';
import { mapActions, mapGetters } from 'vuex';
import instance from '@/api/axiosInstance';

export default {
  data() {
    return {
      orderId: null,
      prepaymentAmount: 200,
      discount: 0, // Знижка за промокодом
      error: '', // Повідомлення про помилку промокоду
      currentStep: 1,
      promoCode: '',
      personalInfo: {
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
      },
      deliveryMethod: '',
      paymentType: 'cash',
      isPaymentProcessing: false,
      wayforpayScriptLoaded: false,
      attempts: 5,
      deliveryInfo: {
        city: '',
        department: '',
        apartment: '',
        street: '',
        house: '',
        paymentType: '',
      }
    };
  },
  mounted() {
    const script = document.createElement('script');
    script.src = "https://secure.wayforpay.com/server/pay-widget.js";
    script.onload = () => {
      console.log('WayForPay script loaded');
    };
    document.head.appendChild(script);
  },
  computed: {
    ...mapGetters(['deliveryFee']),
    delete_button() {
      return delete_button;
    },
    cartItems() {
      return this.$store.state.cart;
    },
    cartTotal() {
      return this.$store.state.cartTotal; // Отримуємо cartTotal з Vuex
    },
    paymentAmount() {
      return this.paymentType === 'full' ? this.cartTotal : this.prepaymentAmount;
    },
  },
  methods: {
    ...mapActions(['addRemoveCart', 'removeItem', 'setDeliveryFee']),
    updateDeliveryFee() {
    const orderTotal = this.cartTotal;

    // Логіка для визначення вартості доставки
    const deliveryFee = this.deliveryMethod === 'pickup' ? 'Безоплатно' :
                        orderTotal >= 3500 ? 'Безоплатно' :
                        this.deliveryMethod === 'nova-poshta' ? 'За тарифом перевізника' :
                        this.deliveryMethod === 'courier' && this.paymentType === 'full' ? 'Безоплатно' :
                        this.deliveryMethod === 'courier' && this.paymentType === 'cash' ? '150 грн.' : '';

    // Встановлюємо вартість доставки у store
    this.$store.commit('setDeliveryFee', deliveryFee);
  },
    applyPromoCode() {
      const promo_Code = this.promoCode; // Пряме присвоєння
      instance.post('/api/v1/orders/promocode/validate/', { code: promo_Code })
        .then(response => {
          this.discount = response.data.discount; // Отримуємо знижку
          this.$store.dispatch('applyPromoCode', { promoCode: promo_Code, discount: this.discount });
          this.error = '';
        })
        .catch(error => {
          this.discount = 0;
          this.error = error.response.data.error || 'Сталася помилка';
        });
    },
    addOrRemove(item, number) {
      if (number === -1 && item.qty <= 1) return;
      const payload = {
        product: { ...item, qty: number },
        toAdd: true,
      };
      this.addRemoveCart(payload);
    },
    updateQuantity(item) {
      const newQty = parseInt(item.qty, 10);
      if (newQty >= 1) {
        item.qty = newQty;
        this.$store.dispatch('updateCart', { product: item });
      } else {
        item.qty = 1;
      }
    },
    removeFromCart(item) {
      this.removeItem({ product: item });
    },
    goToStep(step) {
      this.currentStep = step; // Переходимо до наступного кроку
    },
    goToNextStep() {
      if (this.deliveryMethod === 'pickup') {
        this.submitOrder(); // Пропускаємо оплату, якщо вибрано самовивіз
      } else {
        this.currentStep = 3; // Інакше переходимо до кроку оплати
      }
    },
    selectDeliveryMethod(method) {
    this.deliveryMethod = method; // Вибір способу доставки
    this.updateDeliveryFee();
    if (method === 'pickup') {
      this.deliveryInfo = {
        city: 'Київ', // Встановіть стандартне значення для міста
        department: '', // Відділення не потрібне
        apartment: '', // Квартира не потрібна
        street: '', // Вулиця не потрібна
        house: '', // Будинок не потрібен
      };
    }
  },
  selectPaymentType(type) {
    this.paymentType = type;
    this.updateDeliveryFee(); // Оновлюємо вартість доставки після зміни типу оплати
  },
  submitOrder() {
    const orderData = {
      personalInfo: this.personalInfo,
      deliveryMethod: this.deliveryMethod,
      deliveryInfo: this.deliveryInfo,
      cartItems: this.cartItems,
      orderTotal: this.cartTotal,
      deliveryFee: this.$store.state.deliveryFee, // Правильно врахована доставка
      paymentType: this.paymentType,
    };

    // Створюємо замовлення тільки при першій спробі
    instance.post('/api/v1/orders/create/', orderData)
      .then(response => {
        console.log('Order created successfully', response.data);

        // Зберігаємо інформацію про замовлення в Vuex Store
        this.$store.dispatch('setOrderData', {
          orderId: response.data.order_id,
          personalInfo: this.personalInfo,
          deliveryMethod: this.deliveryMethod,
          deliveryInfo: this.deliveryInfo,
          cartItems: this.cartItems,
          paymentAmount: this.paymentAmount,
        });

        // Очищаємо кошик після успішного замовлення
        this.$store.dispatch('сlearCart');
        
        // Перехід на сторінку підтвердження
        this.$router.push('/confirmation');
      })
      .catch(error => {
        console.error('Error submitting order:', error);
      });
  },

  async handlePayment() {
  const orderData = {
    orderId: this.orderId,
    personalInfo: this.personalInfo,
    deliveryMethod: this.deliveryMethod,
    deliveryInfo: this.deliveryInfo,
    cartItems: this.cartItems,
    orderTotal: this.paymentAmount,
    deliveryFee: this.$store.state.deliveryFee,
    paymentType: this.paymentType,
  };

  try {
      const response = await instance.post('/api/v1/orders/payment/initiate/', orderData);
      const paymentData = response.data.payment_data; // Отримуємо дані для WayForPay
      this.runWayForPay(paymentData); // Ініціалізація платежу

      // Зберігаємо інформацію про замовлення в Vuex Store
      this.$store.dispatch('setOrderData', {
        orderId: this.order_id,
        personalInfo: this.personalInfo,
        deliveryMethod: this.deliveryMethod,
        deliveryInfo: this.deliveryInfo,
        cartItems: this.cartItems,
        paymentAmount: this.paymentAmount,
        orderReference: response.data.payment_id,
      });

      this.orderReference = response.data.payment_id;
      this.orderId = response.data.order_id;

      console.log("Order ID from response:", this.orderId); // Принт для перевірки
    } catch (error) {
      this.isPaymentProcessing = false;
      console.error('Помилка при оплаті:', error);
    }
  },

    runWayForPay(paymentData) {
       // eslint-disable-next-line 
        /* eslint-disable */ 
      if (typeof Wayforpay !== 'undefined') {
        var wayforpay = new Wayforpay();
        wayforpay.run(paymentData,
          response => {
            console.log('Оплата успішна:', response);
            this.startPaymentStatusCheck();
            this.$router.push('/confirmation');
          },
          response => {
            console.log('Оплата не пройшла:', response);
            this.isPaymentProcessing = false;
            this.startPaymentStatusCheck();
          },
          response => {
            console.log('Платіж обробляється:', response);
            this.startPaymentStatusCheck();
          }
        );
      } else {
        console.error('WayForPay script is not loaded');
        this.isPaymentProcessing = false;
      }
    },
    async startPaymentStatusCheck() {
  const checkStatus = async () => {
    try {
      const response = await instance.get(`/api/v1/orders/payment/status/${this.orderReference}/`);
      const transactionStatus = response.data.transactionStatus;


      if (transactionStatus === 'Approved') {
        this.isPaymentProcessing = false;
        this.$router.push('/confirmation'); // Перехід на сторінку успіху
      } else if (['Pending', 'InProcessing', 'WaitingAuthComplete'].includes(transactionStatus)) {
        console.log('Платіж все ще в обробці...');
        setTimeout(checkStatus, 5000); // Повторна перевірка через 5 секунд
      } else {
        this.isPaymentProcessing = false;
        alert('Платіж відхилено або термін дії закінчився.');
      }
    } catch (error) {
      this.isPaymentProcessing = false;
      console.error('Помилка при перевірці статусу платежу:', error);
      setTimeout(checkStatus, 5000); // Повторна перевірка через 5 секунд у випадку помилки
    }
  };

  checkStatus(); // Запуск перевірки статусу платежу
  }
  }
};
</script>



<style scoped>
.checkout-page{
  gap: 20px;
  display:flex;
  margin: 50px 0;
}
.form_page {
  flex: 1;
}
.cart_page {
  flex: 1;
}
.cart_item {
  display:flex;
  align-items: center;
  justify-content: space-around;
}
.cart_item_img img{
  width: 80px;
  height: auto;
}
.cart_item_header {
  display: flex;
  gap: 10px;
}
.icon__delete-button img {
  width: 30px;
  height: auto;
}

.js-qty__wrapper {
    display: inline-block;
    position: relative;
    min-width: 60px;
    overflow: visible;
    background-color: #ffffff;
    color: #000000;
}
.js-qty__adjust {
    background: #fff;
    height: 35px;
    width: 35px;
    text-align: center;
    border: 1px solid #000;
    line-height: 24px;
    padding: 5px 6px;
    display: inline-block;
    float: left;
    cursor: pointer;
    backface-visibility: hidden;
    transition: background-color 0.1s ease-out;
}
.js-qty__num {
    background: #fff;
    height: 31px;
    width: 35px;
    text-align: center;
    border: 1px solid #000;
    line-height: 24px;
    padding: 1px 6px;
    display: inline-block;
    float: left;
    margin: 0 -1px 10px;
    font-size: .9rem;
    -moz-appearance: textfield;
}
.js-qty__num::-webkit-outer-spin-button,
.js-qty__num::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Для Chrome, Safari, Edge */
  margin:0;
}

.cart_info_block {
  margin-left: 40px;
}
.cart_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #ffffff;

    box-shadow: rgb(0 0 0 / 10%) 2px -5px 10px 0;
}
.form_page button,
.discount_prod button {
  text-transform: uppercase;
  background: transparent;
  border: 1px solid #000;
  cursor: pointer;
  transition: 0.35s;
  letter-spacing: 1px;
  color: #000;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 15px;
  font-family: 'MaisonNeue';
  width: 100%;
  margin-bottom: 5px;
}
.form_page button:hover,
.discount_prod button:hover{
  color: #fff;
  background: #000;
}

.qty_wrapper {
  display: flex;
  position: relative;
  min-width: 60px;
  overflow: visible;
  background-color: #ffffff;
  color: #000000;
}
.qty_wrapper input {
    background: #fff;
    height: 31px;
    width: 35px;
    text-align: center;
    border: 1px solid #000;
    line-height: 24px;
    padding: 1px 6px;
    display: inline-block;
    float: left;
    margin: 0 -1px 10px;
    font-size: .9rem;
}
.qty_wrapper button { 
  height: 35px;
  background: #Fff;
  width: 35px;
  text-align: center;
  border: 1px solid #000;
  line-height: 24px;
  padding: 5px 6px;
  display: inline-block;
  float: left;
  cursor: pointer;
  backface-visibility: hidden;
  transition: background-color 0.1s ease-out;
}
.cart_items input {
  background: #fff;
  height: 31px;
  width: 35px;
  text-align: center;
  border: 1px solid #000; /* Чорний бордер */
  line-height: 24px;
  padding: 1px 6px;
  display: inline-block;
  float: left;
  margin: 0 -1px 10px;
  font-size: 0.9rem;
  -moz-appearance: textfield; /* Прибираємо стрілки для Firefox */
}

.cart_items input:focus {
  outline: none; /* Прибираємо стандартну обводку браузера */
  border-color: #000; /* Чорний бордер при фокусі */
}

/* Прибираємо стрілки для Chrome, Safari, Opera */
.cart_items input[type=number]::-webkit-outer-spin-button,
.cart_items input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Прибираємо стрілки для Firefox */
.cart_items input[type=number] {
  -moz-appearance: textfield;
}

.delete_button {
  margin-left: 8px;
  border: none;
  background: none;
}
.delete_button:hover {
  cursor: pointer;
}
.delete_button img{
  width: 30px;
}
.active-button {
  background-color: #009D12; /* Колір для активної кнопки */
  color: #fff;
}
.spinner_block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loading-spinner {
  border: 16px solid #f3f3f3; 
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@media (max-width: 768px){ 
  .checkout-page {
    flex-direction: column-reverse;
  }
  .cart_info_block {
    margin: 0;
  }
  
}
/* Блок, який буде заблюрений */
.content-blurred {
  filter: blur(3px); /* Заблюрення сторінки */
  pointer-events: none; /* Блокування взаємодії з елементами */
}

/* Блок для повідомлення по центру */
.page-unavailable {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9); /* Білий фон з невеликою прозорістю */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.page-unavailable h2 {
  font-size: 2.5rem;
  color: #000;
}

.page-unavailable p {
  font-size: 1.2rem;
  color: #555;
  margin-top: 10px;
  text-align: center;
}
</style>
