<template>
  <div class="wishlist_page">
    <transition name="fade">
      <div v-if="isListVisible" class="cart-bg" @click="closeWishlist"></div>
    </transition>
    <transition name="slide">
      <div class="cart-slide" v-if="isListVisible">
        <div class="title_cart">
          <div class="close-cart">
            <h5>Cписок бажань</h5>
            <button type="button" class="close" @click="closeWishlist">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button> 
          </div>
        </div>
        <div class="wish_list_content">
          <div class="wish_list_items">
            <div class="wish_list_container">
              <div class="wish_list_item" v-for="item in wishListItems" :key="item.id">
  
                <div class="wish_list_img">
                  <img :src="item.image" :alt="item.title" />
                </div>
                <div class="wish_list_action">
                  <div class="wist_list_title">
                    <a>{{ item.name }}</a>
                    <button @click="removeFromWishlist(item)" class="wist_list_title_bnt">
                      <span class="icon__delete-button" aria-hidden="true"> <img :src="delete_button" alt="Delete" /></span>
                    </button>
                  </div>
                  <div class="wish_list_price">
                    <div class="price_p">
                      <p :class="{ 'crossed-price': item.variants[0].discount_price > 0 }">
                                    {{ item?.price }}₴
                                    <button @click="addOrRemove(item)">Купити</button>
                                  </p>

                                  <p class='disc_price' v-if="item.variants.length > 0 && item.variants[0].discount_price > 0">
                                    {{ item.variants[0].discount_price }}₴
                        </p>
                    </div> 
                                 
                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import delete_button from '@/assets/img/ico/delete-button.svg';

export default {
  name: "WishList",
  props: {
    isListVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['cart']),
    delete_button() {
      return delete_button;
    },
    ...mapGetters(['wishListItems']) // Використовуємо getter для отримання списку бажань
  },
  methods: {
    closeWishlist() {
      this.$emit('close_wishlist');
    },
    ...mapActions(['removeFromWishList']),
    removeFromWishlist(item) {
      this.removeFromWishList(item);
    },
    ...mapActions(['addRemoveCart']),
    addOrRemove(item) {
      this.$emit('wh_close_cart');
      this.addRemoveCart({

      toAdd: true,
        product: { 
          ...item,
          qty: 1
                }
            });
        },
  }
};
</script>

<style scoped>
/* Фон */
.cart-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 998;
}

/* Анімація fade для фону */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Панель */
.cart-slide {
  background: #fff;
  width: 20rem;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1001;
  display: flex;
  flex-direction: column;
}

/* Анімація slide для панелі */
.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-enter, .slide-leave-to {
  transform: translateX(100%);
}

/* Заголовок */
.title_cart {
  padding: 10px 15px;
  border-bottom: 1px solid #e8e8e8;
}
.close-cart { 
  display: flex; 
  justify-content: space-between;
}
.title_cart h5 {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.close {
  border: none;
  background: none;
}

/* Контейнер для списку товарів */
.wish_list_container {
  overflow-y: auto; /* Дозволяє вертикальний скролінг */
  max-height: calc(100vh - 150px); /* Обмежує висоту контейнера */
  padding: 10px; /* Відступи */
}

/* Стилі для списку товарів */
.wish_list_item {
  display: flex;
  align-items: center;
  max-width: 284px;
  margin-bottom: 10px; /* Відстань між товарами */
}
.wish_list_img img {
  width: 100px;
  height: auto;
}
.wish_list_action {
  display: flex;
  flex-direction: column;
  width: 100%;
}    
.wist_list_title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.icon__delete-button {
  text-align: center;
  cursor: pointer;
}
.icon__delete-button img {
  width:23px;
  height: 23px;
}
.wist_list_title a {
  font-size: 16px;
  line-height: 16px;
}
.wist_list_title_bnt {
  border:none;
  background: none;
}
.wish_list_price {
  display: flex;
  justify-content: space-between;
}  
.wish_list_price button {
  text-transform: uppercase;
  background: transparent;
  border: 1px solid #000;
  cursor: pointer;
  transition: 0.35s;
  letter-spacing: 1px;
  color: #000;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 15px;
  font-family: 'MaisonNeue';
  margin-bottom: 5px;
}
.wish_list_price button:hover {
  background-color: #000; /* Чорний фон */
  color: #fff; /* Білий текст */ 

}
.disc_price {

  margin-top: -30px;

}
</style>


 
