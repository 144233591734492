<template>
    <div>
      <div v-if="showSidebar" class="overlay" @click="closeSidebar"></div>
      <aside :class="{'sidebar': true, 'show': showSidebar}" @click.stop>
        <div class="title_block">
          <button @click="closeSidebar" class="close-sidebar">
            <!-- Close icon -->
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
          <a href="/" class="logo">
            <img :src="logo" alt="Logo" />
          </a>
        </div>
        <div class="panel_menu">
          <div class="collection_block">
            <span class="title">КОЛЕКЦІЇ</span>
            <div class="menu" v-for="brand in data.brands" :key="brand.name" >
              <ul class="menu_list" >
                <li class="menu_item">
                  <span :class="'icon-mainmenu '+brand.slug"></span>
                  <a :href="'/collection/'+brand.slug" itemprop="url">{{brand.name}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <div class="panel_menu">
            <span class="title" @click="toggleDropdown('skinType')">
                <a href="#">Тип шкіри</a>
                <span class="toggle-icon">{{ isDropdownOpen('skinType') ? '-' : '+' }}</span>
            </span>
            <ul :class="{'submenu': true, 'show': isDropdownOpen('skinType')}" v-for="skin in data.skin_types" :key="skin.name">
                <li><a :href="'/skin-type/'+skin.slug">{{ skin.name }}</a></li>
            </ul>
            </div>

            <div class="panel_menu">
            <span class="title" @click="toggleDropdown('productType')">
                <a href="#">Тип продукту</a>
                <span class="toggle-icon">{{ isDropdownOpen('productType') ? '-' : '+' }}</span>
            </span>
            <ul :class="{'submenu': true, 'show': isDropdownOpen('productType')}" v-for="product in data.product_types" :key="product.name">
              <li><a :href="'/product-type/'+product.slug">{{ product.name }}</a></li>
            </ul>
        </div>

        <ul>
        <li><router-link @click="closeSidebar"  :to="{ name:  'InformativePage', params: { pageType: 'DELIVERY' } }">
                Доставка
              </router-link></li>
              <!-- <li><a href="/pages/sustainable">Стати партнером</a></li> -->
               <li>   <router-link @click="closeSidebar"  :to="{ name: 'InformativePage', params: { pageType: 'LOYALTY' } }">
                Програма Лояльності
              </router-link></li>
               <li>   <router-link @click="closeSidebar"  :to="{ name: 'InformativePage', params: { pageType: 'PAYMENT' } }">
                Оплата
              </router-link></li>
               <li>     <router-link @click="closeSidebar"  :to="{ name: 'InformativePage', params: { pageType: 'TERMS' } }">
                Умови використання
              </router-link></li>
              <li>     <router-link @click="closeSidebar"  :to="{ name: 'ContactsPage'}">
                Контакти
              </router-link></li>
           
              <!-- <li><a href="/pages/contact">Контакти</a></li> -->
           
         

            </ul>
        <div class="panel_menu">
          <a href="/account">
            <span class="icon-myaccount"></span>
            <span class="title">Мій акаунт</span>
          </a>
        </div>
      </aside>
    </div>
  </template>
  
  <script>
  import logo from '@/assets/img/logo.svg';
  import '@/assets/css/Sidebar.css';
  import instance from '@/api/axiosInstance';


  export default {
    name: 'SidebarComponent',
    props: {
      showSidebar: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        data: [],
        baseURL: instance.defaults.baseURL, 
        openDropdown: null // Стан для відкритих списків
      };
    },
    computed: {
      logo() {
        return logo;
      }
    },
    mounted() {
    this.getData()
    },
    methods: {
      closeSidebar() {
        this.$emit('toggle-sidebar');
      },
      toggleDropdown(type) {
        this.openDropdown = this.openDropdown === type ? null : type;
      },
      isDropdownOpen(type) {
        return this.openDropdown === type;
      },
      async getData() {
        try {
        const response = await instance.get('/api/v1/shop/navbar/')
        console.log(response.data)
        this.data = response.data
      } catch (error) {
        console.error('Error fetching brands:', error)
      }
      },
    }
  };
  </script>
  