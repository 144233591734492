<template>
    <div class="product-cart" @mouseover="onHover" @mouseleave="onLeave">
   
      <div class="product-cart-content">
        <a  :href="'/product/'+ product.slug" class="product-cart-link">
          <div class="product-cart-image">
            <!-- Використовуємо тег Transition для анімації -->
            <Transition name="fade">
              <div class="image-wrapper" :class="{ 'blurred': selectedVariant?.stock === 0 }">
                <img :src="imageSrc" alt="" class="product-cart-img lazyloaded" key="imageSrc">
                <!-- Повідомлення, якщо товару немає в наявності -->
                <div v-if="selectedVariant?.stock === 0" class="out-of-stock-message">
                  Немає в наявності
                </div>
              </div>
          </Transition>
          </div>
        </a>
          <div class="product-cart-meta">
            <div class="product-cart-meta-main">
              <div class="product-cart__title">{{ product.name }}</div>
              <div class="product-cart__subtitle">{{ product.human }}</div>
            </div>
            <div class="product-cart-meta-price">
              <span :class="{ 'crossed-price': selectedVariant?.discount_price > 0 }">
              {{ selectedVariant?.price }} грн
            </span>
            <span v-if="selectedVariant?.discount_price > 0">
              {{ selectedVariant?.discount_price }} грн
            </span>
            </div>
            <div class="product-cart-meta-secondary">
              <div class="product-cart-copacity">
                <div v-if="product.variants.length > 1" class="variant-buttons">
                  <button 
                    v-for="(variant, index) in product.variants" 
                    :key="index" 
                    :class="{'selected-variant': selectedVariant.value === variant.value}" 
                    @click="selectVariant(variant)">
                    {{ variant.value }} {{ product.unit }}
                  </button>
                </div>
                <div v-else class="single-variant">
                  {{ selectedVariant.value }} {{ product.unit }}
                </div>
              </div>
            </div>
          </div>
     
            <div class="product-action-wrapper">
              <button class="product-action" type="button" @click="addOrRemove(product)">Додати в кошик</button>
              <button class="product-action" type="button" @click="addProdToWishlist(product)">Додати в Вішліст</button>
            </div>
        

      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapState } from 'vuex';
  import '@/assets/css/product_cart.css';
  
  export default {
    name: "ProductCart",
    props: {
      product: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
        selectedVariant: this.product.variants[0], // Початково вибраний перший варіант
        placeholder: '/media/content/free.png', // URL до заглушки
        isTransitioning: false, // Стан анімації переходу
        imageSrc: this.product.variants[0].images[0] ? this.product.variants[0].images[0].image : this.placeholder, // Ініціалізація зображення
      };
    },
    computed: {
      ...mapState(['cart', 'wish_list']),
    },
    methods: {
      ...mapActions(['addRemoveCart', 'addToWishList']),
      
      addOrRemove(product) {
        this.$emit('s_i_close_cart');
        this.addRemoveCart({
          toAdd: true,
          product: {
            ...product,
            qty: 1,
            price: this.selectedVariant.price,
            id: this.selectedVariant.id,
            code: this.selectedVariant.code,
            value:  this.selectedVariant.value,
            image: this.imageSrc
          },
        });
      },
      
      addProdToWishlist(product) {
        this.$emit('s_i_close_wish');
        this.addToWishList({
            ...product,
            qty: 1,
            price: this.selectedVariant.price,
            id: this.selectedVariant.id,
            code: this.selectedVariant.code,
            value:  this.selectedVariant.value,
            image: this.imageSrc
          }
        );
      },
      
      selectVariant(variant) {
        this.selectedVariant = variant;
        this.updateImage();
      },
  
      updateImage() {
        this.isTransitioning = true;
        setTimeout(() => {
          this.imageSrc = this.selectedVariant.images[0]
            ? `${this.selectedVariant.images[0].image}`
            : this.placeholder;
          this.isTransitioning = false;
        }, 300);
      },
  
      onHover() {
        this.isTransitioning = true;
        setTimeout(() => {
          if (this.selectedVariant.images[1]) {
            this.imageSrc = `${this.selectedVariant.images[1].image}`;
          }
          this.isTransitioning = false;
        }, 300);
      },
  
      onLeave() {
        this.isTransitioning = true;
        setTimeout(() => {
          this.imageSrc = this.selectedVariant.images[0]
            ? `${this.selectedVariant.images[0].image}`
            : this.placeholder;
          this.isTransitioning = false;
        }, 300);
      },
    },
  };
  </script>
  