<template>
    <div class="quiz_components">
        <a href="#" class="quiz_link">
            <picture class="rte_image">
                <source media="(max-width: 480px)" srcset="https://cdn.shopify.com/s/files/1/0325/6398/6572/files/home_banner_skinquiz_mobile_en.jpg?v=1665042087">
                <img class="img-fluid" src="https://cdn.shopify.com/s/files/1/0325/6398/6572/files/home_banner_skinquiz_en.jpg?v=1665042088" alt="Skin Quiz Launch">
            </picture>

        </a>
    </div>
</template>

<style scoped>
.quiz_components{ 
    margin-top: -7px;
}
img {
    max-width: 100%;
}
</style>