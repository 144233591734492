<template>
  <div class="confirmation-page _container">
    <h2>Дякуємо за замовлення, наш менеджер зв‘яжеться з Вами найближчим часом для підтвердження.</h2>

    <div class="order-summary">
      <h4>Інформація про замовлення:</h4>
      <div>
        <strong>Номер замовлення:</strong> {{ order.orderId }}
      </div>
      <div>
        <strong>Ім'я:</strong> {{ order.personalInfo.firstName }} {{ order.personalInfo.lastName }}
      </div>
      <div>
        <strong>Email:</strong> {{ order.personalInfo.email }}
      </div>
      <div>
        <strong>Номер телефону:</strong> {{ order.personalInfo.phone }}
      </div>
      <div>
        <strong>Отримання:</strong>
        <span v-if="order.deliveryMethod === 'courier'">Кур'єром за адресою: {{ order.deliveryInfo.street }}, {{ order.deliveryInfo.house }}, кв. {{ order.deliveryInfo.apartment }}</span>
        <span v-if="order.deliveryMethod === 'nova-poshta'">Новою поштою: Населений пункт: {{ order.deliveryInfo.city }}, Відділення: {{ order.deliveryInfo.department }}</span>
        <span v-if="order.deliveryMethod === 'pickup'">Самовивіз з м. Київ, вулиця Григорія Кочура, 16а</span>
      </div>

      <div class="cart-items">
        <h4>Ваше замовлення:</h4>
        <div v-for="(item, index) in order.cartItems" :key="index" class="cart-item">
          <img :src="item.image" alt="item.name" class="item-image" />
          <div class="item-details">
            <div class="item-name">{{ item.name }}</div>
            <div class="item-qty">Кількість: {{ item.qty }}</div>
            <div class="item-price">{{ item.price }} грн</div>
          </div>
        </div>
      </div>

      <div class="total-price">
        <strong>Загальна сума:</strong> {{ order.paymentAmount }} грн
      </div>
    </div>

    <button class="btn btn-primary" @click="clearCartAndReturn">Повернутись на головну</button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters(['orderData']), // Витягуємо дані замовлення з Vuex Store
    order() {
      return this.orderData;
    }
  },
  methods: {
    ...mapActions(['clearOrderData', 'сlearCart']),
    clearCartAndReturn() {
      this.сlearCart(); // Очищаємо кошик
      this.clearOrderData(); // Очищаємо дані замовлення
      this.$router.push('/'); // Повертаємось на головну сторінку
    }
  }
};
</script>

<style scoped>
.confirmation-page {
  margin: 20px;
}
.confirmation-page h2 {
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 36px;
}

.order-summary {
  margin-top: 20px;
  text-align: left;
}
.order-summary h4{ 
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 40px;
  letter-spacing: 0.5px;
}
.order-summary strong {
  font-weight: 500;
}
.cart-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.item-details {
  flex: 1;
}

.total-price {
  margin-top: 20px;
  font-weight: bold;
}

.btn {
  margin-top: 20px;
  text-transform: uppercase;
    background: transparent;
    border: 1px solid #000;
    cursor: pointer;
    transition: 0.35s;
    width: 95%;
    letter-spacing: 1px;
    color: #000;
    font-weight: 600;
    font-size: 12px;
    padding: 10px 15px;
    font-family: 'MaisonNeue';
    margin-bottom: 5px;
}
.btn:hover {
  background: black;
  color: white;
}
@media ( max-width: 768px) {
 .cart-items {
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
 }
 .item-details {
  display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    text-wrap: nowrap;
 }
}
  
</style>
