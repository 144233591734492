<template>
  <footer class="site-footer">
    <div class="footer__section">
      <div class="page-width">
        <div class="footer__blocks">
          <!-- Блок COLLECTIONS -->
          <div class="footer__block" data-type="menu">
            <div class="footer__title">Коллекція</div>
            <ul class="footer__menu _collections">
              <li v-for="brand in brands" :key="brand.slug">
                <a :href="`/collection/${brand.slug}`">{{ brand.name }}</a>
              </li>
            </ul>
          </div>

          <!-- Блок SKINCARE -->
          <div class="footer__block" data-type="menu">
            <div class="footer__title">Тип шкіри</div>
            <ul class="footer__menu">
              <li v-for="skinType in skinTypes" :key="skinType.slug">
                <a :href="`/skin-type/${skinType.slug}`">{{ skinType.name }}</a>
              </li>
            </ul>
          </div>

          <!-- Блок PRODUCT TYPES -->
          <div class="footer__block" data-type="menu">
            <div class="footer__title">Тип продукту</div>
            <ul class="footer__menu">
              <li v-for="productType in productTypes" :key="productType.slug">
                <a :href="`/product-type/${productType.slug}`">{{ productType.name }}</a>
              </li>
            </ul>
          </div>
            <!-- Блок COMPANY -->
          <div class="footer__block" data-type="menu">
            <div class="footer__title">Компанія</div>
            <ul class="footer__menu">
              <!-- <li><a href="/pages/about-us"> Про нас</a></li> -->
              <li><router-link :to="{ name: 'InformativePage', params: { pageType: 'DELIVERY' } }">
                Доставка
              </router-link></li>
              <!-- <li><a href="/pages/sustainable">Стати партнером</a></li> -->
               <li>   <router-link :to="{ name: 'InformativePage', params: { pageType: 'LOYALTY' } }">
                Програма Лояльності
              </router-link></li>
               <li>   <router-link :to="{ name: 'InformativePage', params: { pageType: 'PAYMENT' } }">
                Оплата
              </router-link></li>
               <li>     <router-link :to="{ name: 'InformativePage', params: { pageType: 'TERMS' } }">
                Умови використання
              </router-link></li>
           
              <li><a href="/contacts">Контакти</a></li>
           
         

            </ul>
          </div>
          
        </div>
      </div>
    </div>

    <!-- Блок з правами та інші деталі -->
    <div class="footer__bottom">
      <div class="page-width">
        <p>&copy; 2022 Niche Beauty Lab. Всі права захищені.</p>
      </div>
    </div>
  </footer>
</template>

<script>
import instance from '@/api/axiosInstance'; // Імпортуємо інстанс axios для API запитів

export default {
  name: 'FooterComponent',
  data() {
    return {
      brands: [],
      skinTypes: [],
      productTypes: [],
    };
  },
  mounted() {
    instance.get('/api/v1/shop/navbar/')
      .then(response => {
        const { brands, skin_types, product_types } = response.data;
        this.brands = brands;
        this.skinTypes = skin_types;
        this.productTypes = product_types;
      })
      .catch(error => {
        console.error('Error fetching navbar data:', error);
      });
  }
}
</script>

<style>
footer {
  background-color: #000;
  color: white;
  padding: 1rem;
  text-align: center;
}
.footer__blocks {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 50px 0;
}

@media (max-width: 768px) {
  .footer__section {
    padding: 20px;
  }
  .footer__blocks {
    flex-wrap: wrap;
  }
  .footer__block {
    max-width: none;
    flex: 1 1 50%;
  }
}
.footer__title {
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  font-size: .9rem;
  margin-bottom: 15px;
  font-family: 'MaisonNeue';
  letter-spacing: 3px;
  text-align: left;
}
.footer__menu {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  text-align: start;
}
.footer__menu a {
  display: inline-block;
  color: #fff;
  line-height: 1.5rem;
  font-size: 14px;
  cursor: pointer;
  font-weight: 100;
  white-space: nowrap;
  font-family: system-ui;
}
._collections a{
  text-transform: uppercase;
}
</style>
