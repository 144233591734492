import instance from '@/api/axiosInstance';
import { setAuthToken, getAuthToken, removeAuthToken } from '@/utils/cookie.js';
import Cookies from 'js-cookie'; // Імпортуємо js-cookie

const auth = {
  namespaced: true,
  state: {
    token: getAuthToken() || '',
    refreshToken: Cookies.get('refresh_token') || '', // Отримуємо refresh_token з куки
    user: null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      setAuthToken(token); // Зберігаємо access токен у куки
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
      Cookies.set('refresh_token', refreshToken, {
        expires: 7, // Термін дії refresh токена - 7 днів
        sameSite: 'Lax', 
      }); // Зберігаємо refresh_token у куки
    },
    setUser(state, user) {
      state.user = user;
    },
    logout(state) {
      state.token = '';
      state.refreshToken = '';
      state.user = null;
      removeAuthToken(); // Видаляємо access токен з куки
      Cookies.remove('refresh_token'); // Видаляємо refresh токен з куки
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await instance.post('/api/v1/jwt/create/', credentials);
        const token = response.data.access;
        const refreshToken = response.data.refresh; 
        commit('setToken', token);
        commit('setRefreshToken', refreshToken);
        await this.dispatch('auth/getUserInfo');
        return response;
      } catch (error) {
        console.error('Login error:', error);
        throw error;
      }
    },
    async logout({ commit }) {
      commit('logout');
    },
    async refreshToken({ commit, state }) {
      try {
        const refreshToken = state.refreshToken;
        if (refreshToken) {
          const response = await instance.post('/api/v1/jwt/refresh/', { refresh: refreshToken });
          const newToken = response.data.access;
          commit('setToken', newToken); 
          return newToken;
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
      }
    },
    async getUserInfo({ commit, state }) {
      try {
        const token = state.token;
        if (token) {
          const response = await instance.get('/api/v1/users/me/', {
            headers: { Authorization: `Bearer ${token}` },
          });
          commit('setUser', response.data);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
        commit('logout');
      }
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.token;
    },
    currentUser(state) {
      return state.user;
    },
  },
};

export default auth;
