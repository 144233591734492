import { createRouter, createWebHistory } from "vue-router";
import store from "@/store"; // Імпортуємо Vuex store
import Home from "@/views/PageHome.vue";
import LoginPage from "@/views/account/LoginPage.vue";
import RegisterPage from "@/views/account/RegisterPage.vue";
import ResetPasswordPage from "@/views/account/ResetPasswordPage.vue";
import AccountPage from "@/views/account/AccountPage.vue";
import ChangePasswordPage from "@/views/account/ChangePasswordPage.vue";
import UserActivationComponent from "@/views/account/UserActivationComponent.vue";
import ResetPasswordConfirm from "@/views/account/ResetPasswordConfirm.vue";
import RegistrationSuccess from '@/views/account/RegistrationSuccess.vue';
import ResetPasswordSuccess from '@/views/account/ResetPasswordSuccess.vue';
import BrandPage from '@/views/BrandPage.vue';
import SkinTypePage from "@/views/SkinTypePage.vue";
import ProductTypePage from "@/views/ProductTypePage.vue";
import ProductPage from "@/views/ProductPage.vue";
import CheckoutPage from "@/views/CheckoutPage.vue";
import ConfirmationPage from "@/views/ConfirmationPage.vue";
import InformativePage from '@/views/InformativePage.vue';
import PlaceholderPage from '@/views/PlaceholderPage.vue';
import ContactsPage from '@/views/ContactsPage.vue';

const routes = [
  { path: '/home', component: Home, name: 'Home', alias: '/' },
  { path: '/login', component: LoginPage, name: 'Login' },
  { path: '/register', component: RegisterPage, name: 'Register' },
  { path: '/reset-password', component: ResetPasswordPage, name: 'ResetPassword' },
  { path: '/change-password', component: ChangePasswordPage, name: 'ChangePassword' },
  { 
    path: '/account', 
    component: AccountPage, 
    name: 'Account',
    meta: { requiresAuth: true }, // Маршрут вимагає автентифікації
  },
  {
    path: '/user/activate/:uid/:token',
    name: 'UserActivation',
    component: UserActivationComponent
  },
  {
    path: '/password/reset/confirm/:uid/:token',
    name: 'ResetPasswordConfirm',
    component: ResetPasswordConfirm,
  },
  {
    path: '/registration-success',
    name: 'RegistrationSuccess',
    component: RegistrationSuccess,
  },
  {
    path: '/reset-password-success',
    name: 'ResetPasswordSuccess',
    component: ResetPasswordSuccess,
  },
  {
    path: '/collection/:brand',
    name: 'Brand',
    component: BrandPage,
  }, 
  {
    path: '/skin-type/:slug',
    name: 'SkinType',
    component: SkinTypePage,
  },
  {
    path: '/product-type/:slug',
    name: 'ProductType',
    component: ProductTypePage,
  },
  {
    path: '/product/:slug',
    name: 'ProductPage',
    component: ProductPage,
  },
  {
    path: '/checkout',
    name: 'CheckoutPage ',
    component: CheckoutPage,
  },
  {
  path: '/confirmation',
  name: 'ConfirmationPage',
  component: ConfirmationPage,
  },
  {
    path: '/page/:pageType', // Маршрут для інформативних сторінок
    name: 'InformativePage',
    component: InformativePage,
    props: true // Це дозволяє передавати параметр маршруту як пропс
  },
  {
    path: '/contacts',
    name: 'ContactsPage',
    component: ContactsPage,
  },
  {
    path: '/placeholder',
    name: 'PlaceholderPage',
    component: PlaceholderPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Додаємо навігаційний guard для перевірки авторизації
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated']; // Перевірка авторизації через Vuex
  
  if (to.name === 'Login' && isAuthenticated) {
    // Якщо користувач вже авторизований, перенаправляємо на сторінку акаунту
    next({ name: 'Account' });
  } else if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    // Якщо маршрут вимагає авторизації, але користувач не авторизований, перенаправляємо на сторінку входу
    next({ name: 'Login' });
  } else {
    // Продовжуємо навігацію
    next();
  }
});

export default router;
