<template>
  <div class="login-container">
    <form @submit.prevent="handleRegister" class="login-form _register">
      <h2>Реєстрація користувача</h2>

      <!-- Ім'я та Прізвище -->
      <div class="form-row">
        <div class="form-group">
          <label for="name"></label>
          <input
            type="text"
            id="name"
            v-model="name"
            required
            placeholder="Ім'я"
          />
        </div>
        <div class="form-group">
          <label for="surname"></label>
          <input
            type="text"
            id="surname"
            v-model="surname"
            required
            placeholder="Прізвище"
          />
        </div>
      </div>

      <!-- Email та Телефон -->
      <div class="form-row">
        <div class="form-group">
          <label for="email"></label>
          <input
            type="email"
            id="email"
            v-model="email"
            required
            placeholder="Email"
          />
        </div>
        <div class="form-group">
          <label for="phone"></label>
          <input
            type="text"
            id="phone"
            v-model="phone"
            required
            placeholder="Номер телефону"
            @input="validatePhone"
          />
        </div>
      </div>

      <!-- Пароль та Підтвердження паролю -->
      <div class="form-row">
        <div class="form-group password-group">
          <label for="password"></label>
          <input
            :type="showPassword ? 'text' : 'password'"
            id="password"
            v-model="password"
            required
            placeholder="Пароль"
          />
          <span class="toggle-password" @click="togglePassword">
            <svg v-if="!showPassword" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off">
              <path d="M17.94 17.94A10.94 10.94 0 0112 20c-7 0-11-8-11-8s4-8 11-8c3.43 0 6.58 1.28 9 3.54"></path>
              <path d="M1 1l22 22"></path>
            </svg>
          </span>
        </div>
        <div class="form-group password-group">
          <label for="confirmPassword"></label>
          <input
            :type="showConfirmPassword ? 'text' : 'password'"
            id="confirmPassword"
            v-model="confirmPassword"
            required
            placeholder="Пароль ще раз"
          />
          <span class="toggle-password" @click="toggleConfirmPassword">
            <svg v-if="!showConfirmPassword" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off">
              <path d="M17.94 17.94A10.94 10.94 0 0112 20c-7 0-11-8-11-8s4-8 11-8c3.43 0 6.58 1.28 9 3.54"></path>
              <path d="M1 1l22 22"></path>
            </svg>
          </span>
        </div>
      </div>
            <!-- Помилки для телефону -->
      <div v-if="phoneErrors.length" class="error-message">
        <ul>
          <li v-for="(error, index) in phoneErrors" :key="index">{{ error }}</li>
        </ul>
      </div>

      <!-- Помилки для паролю -->
      <div v-if="newPasswordErrors.length" class="error-message">
        <ul>
          <li v-for="(error, index) in newPasswordErrors" :key="index">{{ error }}</li>
        </ul>
      </div>
      
      <button type="submit" class="login-button">Реєстрація</button>
      <div v-if="loading" class="loader">Зачекайте...</div> <!-- Ваш бігунок -->
      <div class="form-links">
        <router-link to="/login">Вже маєте акаунт?</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import load_animation from '@/assets/img/ico/loading.svg';


export default {
  name: "RegisterPage",
  data() {
      return {
        name: "",
        surname: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        showPassword: false,
        showConfirmPassword: false,
        phoneErrors: [],
        newPasswordErrors: [],
        loading: false, 
      };
    },
    computed: {
      load_animation() {
      return load_animation;
    },},

  methods: {
    ...mapActions('auth', ["register"]),
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    validatePhone() {
      const phoneRegex = /^\+?[0-9]{10,15}$/;
      this.phoneErrors = []; // Очищення попередніх помилок
      if (!phoneRegex.test(this.phone)) {
        this.phoneErrors.push("Невірний формат телефону");
      }
      return this.phoneErrors.length === 0; // Повертає true, якщо немає помилок
    },
    validatePassword(password) {
      const errors = [];
      if (password.length < 8) {
        errors.push('Пароль занадто короткий. Мінімум 8 символів.');
      }
      if (!/[A-Z]/.test(password)) {
        errors.push('Пароль повинен містити хоча б одну велику літеру.');
      }
      if (!/[a-z]/.test(password)) {
        errors.push('Пароль повинен містити хоча б одну малу літеру.');
      }
      if (!/[0-9]/.test(password)) {
        errors.push('Пароль повинен містити хоча б одну цифру.');
      }
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        errors.push('Пароль повинен містити принаймні один спеціальний символ.');
      }
      this.newPasswordErrors = errors; // Оновіть масив помилок
      return errors.length === 0; // Повертає true, якщо немає помилок
    },

    handleRegister() {
      this.loading = true; // Set loading to true
      console.log("Loading state:", this.loading); // Debugging line
      this.newPasswordErrors = []; // Очищення попередніх помилок
      if (!this.validatePassword(this.password)) {
        return; // Якщо є помилки, виходимо з функції
      }

      if (this.password !== this.confirmPassword) {
        alert("Паролі не збігаються");
        return;
      }

      if (!this.validatePhone()) {
        return; // Якщо є помилки, виходимо з функції
      }
      this.loading = true; // Встановлюємо loading в true

      this.register({
        first_name: this.name,
        last_name: this.surname,
        email: this.email,
        phone_number: this.phone,
        password: this.password,
        re_password: this.confirmPassword,
      })
        .then(() => {
          // Перенаправлення на сторінку успішної реєстрації
          console.log("Реєстрація успішна!"); 
          this.$router.push("/registration-success");
        })
        .catch((error) => {
          console.error("Помилка реєстрації:", error);
          alert("Не вдалося зареєструватися. Спробуйте ще раз.");
        })
        .finally(() => {
          this.loading = false; // Set loading to false
          console.log("Loading state:", this.loading); // Debugging line
  });
    }
  }

  };
</script>

<style src="@/assets/css/Login.css"></style>
<style src="@/assets/css/load_animation.css"></style>