import { createStore } from 'vuex';
import auth from './modules/auth';

const store = createStore({
  state: {
    wish_list: [],
    cart: [],
    cartTotal: 0,
    discount: 0,
    deliveryFee: ' Безоплатно',
    promoCode: null,
    order: { // Стан для збереження даних замовлення
      orderId: null,
      personalInfo: {},
      deliveryMethod: '',
      deliveryInfo: {},
      cartItems: [],
      paymentAmount: 0,
      orderReference: null,
    },
  },
  mutations: {
    setDeliveryFee(state, fee) {
      state.deliveryFee = fee;
    },
    // Мутації для збереження інформації про замовлення
    setOrderData(state, orderData) {
      state.order = { ...orderData };
    },
    clearOrderData(state) {
      state.order = { // Очищуємо всі дані замовлення
        orderId: null,
        personalInfo: {},
        deliveryMethod: '',
        deliveryInfo: {},
        cartItems: [],
        paymentAmount: 0,
        orderReference: null,
      };
    },
    // Додавання товару до вішліста
    addProductToWish(state, product) {
      const existingProduct = state.wish_list.find(item => item.id === product.id);
      if (!existingProduct) {
        state.wish_list.push(product);
        localStorage.setItem('wish_list', JSON.stringify(state.wish_list));
      }
    },
    // Видалення товару з вішліста
    removeProductFromWish(state, product) {
      state.wish_list = state.wish_list.filter(item => item.id !== product.id);
      localStorage.setItem('wish_list', JSON.stringify(state.wish_list));
    },
    // Завантаження вішліста з localStorage
    loadWishList(state) {
      const storedWishList = localStorage.getItem('wish_list');
      if (storedWishList) {
        state.wish_list = JSON.parse(storedWishList);
      }
    },
    // Додавання або видалення товарів у/з кошика
    addRemoveCart(state, payload) {
      const existingProduct = state.cart.find(item => item.id === payload.product.id);
      if (payload.toAdd) {
        if (existingProduct) {
          existingProduct.qty += payload.product.qty;
        } else {
          state.cart.push({ ...payload.product });
        }
      } else {
        state.cart = state.cart.filter(item => item.id !== payload.product.id);
      }
      state.cartTotal = calculateTotal(state.cart, state.discount);
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    // Оновлення кількості товарів у кошику
    updateCart(state, payload) {
      const product = state.cart.find(o => o.id === payload.product.id);
      if (product) {
        product.qty = payload.product.qty;
        if (product.qty <= 0) {
          state.cart = state.cart.filter(item => item.id !== product.id);
        }
        state.cartTotal = calculateTotal(state.cart, state.discount);
        localStorage.setItem('cart', JSON.stringify(state.cart));
      }
    },
    // Завантаження кошика з localStorage
    loadCart(state) {
      const storedCart = localStorage.getItem('cart');
      if (storedCart) {
        state.cart = JSON.parse(storedCart);
        state.cartTotal = calculateTotal(state.cart, state.discount);
      }
    },
    // Видалення товару з кошика
    removeItem(state, payload) {
      state.cart = state.cart.filter(item => item.id !== payload.product.id);
      state.cartTotal = calculateTotal(state.cart, state.discount);
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    // Очищення кошика після оформлення замовлення
    сlearCart(state) {
      state.cart = [];
      state.cartTotal = 0;
      state.discount = 0;
      state.deliveryFee = 0;
      state.promoCode = null;
      localStorage.removeItem('cart');
    },
    // Застосування промокоду
    applyPromoCode(state, { promoCode, discount }) {
      state.promoCode = promoCode;
      state.discount = discount;
      state.cartTotal = calculateTotal(state.cart, state.discount);
    },
  },
  actions: {
    addToWishList({ commit }, product) {
      commit('addProductToWish', product);
    },
    removeFromWishList({ commit }, product) {
      commit('removeProductFromWish', product);
    },
    loadWishList({ commit }) {
      commit('loadWishList');
    },
    addRemoveCart({ commit }, payload) {
      commit('addRemoveCart', payload);
    },
    loadCart({ commit }) {
      commit('loadCart');
    },
    updateCart({ commit }, payload) {
      commit('updateCart', payload);
    },
    removeItem({ commit }, payload) {
      commit('removeItem', payload);
    },
    setOrderData({ commit }, orderData) {
      commit('setOrderData', orderData);
    },
    clearOrderData({ commit }) {
      commit('clearOrderData');
    },
    сlearCart({ commit }) {
      commit('сlearCart');
    },
    applyPromoCode({ commit }, { promoCode, discount }) {
      commit('applyPromoCode', { promoCode, discount });
    },
    
  },
  getters: {
    cartTotalWithDiscount(state) {
      return state.cartTotal;
    },
    orderData: (state) => state.order, // Геттер для отримання даних замовлення
    wishListItems(state) {
      return state.wish_list;
    },
    isInWishlist: (state) => (productId) => {
      return state.wish_list.some(item => item.id === productId);
    },
    deliveryFee(state) {
      return state.deliveryFee;
    },
  },
  modules: {
    auth,
  },
});
function calculateTotal(cart, discount) {
  let total = cart.reduce((accumulator, item) => {
    // Якщо є знижка (discount_price > 0) у першого варіанта, використовуємо її, інакше звичайну ціну
    const priceToUse = item.variants && item.variants.length > 0 && item.variants[0]?.discount_price > 0
      ? item.variants[0].discount_price
      : item.price;

    return accumulator + parseFloat(priceToUse.replace(',', '.')) * item.qty;
  }, 0);
  
  // Застосування загальної знижки до суми
  total = total - total * (discount / 100);
  return parseFloat(total).toFixed(2); // Залишаємо два знаки після коми
}


// Завантаження кошика та вішліста при створенні store
store.dispatch('loadCart');
store.dispatch('loadWishList');

export default store;
