<template>
  <div class="account-container _container">
    <!-- Ліва колонка з меню -->
    <div class="menu-column">
      <nav>
        <ul>
          <li @click="currentSection = 'profile'" :class="{ active: currentSection === 'profile' }">Особиста інформація</li>
          <li @click="currentSection = 'orders'" :class="{ active: currentSection === 'orders' }">Мої замовлення</li>
        </ul>
        <button @click="handleLogout" class="logout-button">Вийти</button>
      </nav>
    </div>

    <!-- Права колонка з контентом -->
    <section class="content">
      <!-- Відображаємо відповідну секцію на основі вибору в меню -->
      <div v-if="currentSection === 'profile'" class="profile-section">
        <div class="header">
          <h2>Особисті дані</h2>
          <button @click="saveUserData" class="edit-button">Зберегти</button>
        </div>

        <div class="profile-info-grid">
          <div class="profile-info-item">
            <span class="label">Ім'я:</span>
            <input v-model="user.first_name" class="value" />
          </div>
          <div class="profile-info-item">
            <span class="label">Прізвище:</span>
            <input v-model="user.last_name" class="value" />
          </div>
        </div>

        <div class="header">
          <h2>Контакти</h2>
        </div>

        <div class="profile-info-grid">
          <div class="profile-info-item">
            <span class="label">Номер телефону:</span>
            <input v-model="user.phone_number" class="value" />
          </div>
          <div class="profile-info-item">
            <span class="label">Email:</span>
            <input v-model="user.email" class="value" />
          </div>
        </div>
      </div>

      <!-- Замовлення -->
      <div v-else-if="currentSection === 'orders'" class="orders-section">
        <h2>Мої замовлення</h2>
        <div v-if="orders.length > 0">
          <div class="order" v-for="(order, index) in orders" :key="order.id">
            <div 
              class="order-summary"
              @mouseover="hoveredOrder = index"
              @mouseleave="hoveredOrder = null"
            >
              <span class="order-number">Замовлення #{{ order.id }}</span>
              <span class="order-status">Статус: {{ order.status }}</span>
              <span class="order-delivery">Тип доставки: {{ order.delivery_type }}</span>
              <span class="order-total">Сума: {{ order.total_price }} грн</span>
              <span class="order-time">Час замовлення: {{ order.created }}</span>
            </div>

            <div v-if="hoveredOrder === index" class="order-products">
              <h3>Продукти у замовленні:</h3>
              <ul>
                <li v-for="product in order.order_products" :key="product.variant">
                  {{ product.name }} - {{ product.quantity }} шт. за {{ product.price }} грн
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-else>
          <p>У вас ще немає замовлень.</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import instance from '@/api/axiosInstance'; // Використовуємо axios для API запитів
import Cookies from 'js-cookie'; // Використовуємо куки для аутентифікації

export default {
  name: 'AccountPage',
  data() {
    return {
      currentSection: 'profile', // Відображаємо розділ "Особиста інформація" за замовчуванням
      user: {
        email: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        city: ''
      },
      orders: [],
      hoveredOrder: null // Для відстеження замовлення, на яке наведено курсор
    };
  },
  mounted() {
    this.fetchUserData(); // Викликаємо метод для отримання даних користувача та замовлень
  },
  methods: {
    async fetchUserData() {
      try {
        const token = Cookies.get('authToken');
        if (token) {
          const response = await instance.get('/api/v1/actions/orders/', {
            headers: { Authorization: `Bearer ${token}` },
          });
          this.user = response.data.user; // Отримуємо інформацію про користувача
          this.orders = response.data.orders; // Отримуємо замовлення
        }
      } catch (error) {
        console.error('Помилка отримання даних користувача:', error);
      }
    },
    async saveUserData() {
      try {
        const token = Cookies.get('authToken');
        if (token) {
          await instance.put('/api/v1/actions/update/', this.user, {
            headers: { Authorization: `Bearer ${token}` },
          });
          console.log('Дані успішно збережено');
        }
      } catch (error) {
        console.error('Помилка збереження даних користувача:', error);
      }
    },
    async handleLogout() {
      Cookies.remove('authToken'); // Видаляємо токен
      Cookies.remove('refresh_token'); // Видаляємо refresh токен
      await this.$router.push('/login'); // Перенаправляємо на сторінку логіну
      window.location.reload(); // Оновлюємо сторінку після перенаправлення
    }
  }
};
</script>


<style scoped>
.account-container {
  min-height: 700px;
  display: flex;
  margin: 20px;
  align-items: flex-start;
  justify-content: space-between;
}
.menu-column {
  position: relative;
  display: grid;
  grid-gap: 16px;
  margin-top: 20px;
  background-color: var(--clr-1-25);
  border-radius: 16px;
}

nav ul {
  list-style: none; /* Видаляє крапки перед елементами списку */
  padding: 0; /* Прибирає відступи */
  margin: 0; /* Прибирає відступи */
}

nav ul li {
  display: flex !important;
    align-items: center;
    text-align: center !important;
    justify-content: center;
    font-family: MaisonNeue;
    line-height: inherit;
    text-transform: uppercase;
    opacity: 1;
    color: #000;
    font-weight: 900;
    background: #fff;
    border: 1px solid #000000;
    height: 42px;
    border-radius: 0 !important;
    margin-top: 3px !important;
    letter-spacing: 1px;
    letter-spacing: 1px;
    margin: 0 auto;
    min-width: 165px;
    width: 95%;
    min-height: 54px;
}

nav ul li.active {
  background-color: #000;
  color: #fff; /* Колір активного пункту меню */
}

nav ul li:hover {
  background-color: #000;
  color: #fff; /* Колір активного пункту меню */
}
.logout-button {
  display: flex !important;
    align-items: center;
    text-align: center !important;
    justify-content: center;
    font-family: MaisonNeue;
    line-height: inherit;
    text-transform: uppercase;
    opacity: 1;
    color: #000;
    font-weight: 900;
    background: #fff;
    border: 1px solid #000000;
    height: 42px;
    border-radius: 0 !important;
    margin-top: 3px !important;
    letter-spacing: 1px;
    letter-spacing: 1px;
    margin: 0 auto;
    min-width: 165px;
    width: 95%;
    min-height: 54px;
}
.logout-button:hover {
  background-color: #000;
  color: #fff; 
}
.profile-section {
  margin-bottom: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #000; /* Синя лінія під заголовком */
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.edit-button {
  background: transparent;
  border: none;
  color: #000;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
}

.profile-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Дві колонки */
  gap: 20px; /* Простір між колонками */
  margin-bottom: 40px; /* Простір між блоками */
}

.profile-info-item {
  display: flex;
  justify-content: space-between;
}

.label {
  font-weight: 600;
  color: #666;
}

.value {
  font-weight: 400;
  color: #333;
}
.content {
  width: 70%;
}
.orders-section {
  margin-top: 20px;
}

.order {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.order-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.order-summary:hover {
  background-color: #f0f0f0;
}

.order-number, .order-status, .order-delivery, .order-total, .order-time {
  flex-basis: 20%;
  text-align: center;
}

.order-products {
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.order-products h3 {
  margin-bottom: 10px;
}

.order-products ul {
  list-style: none;
  padding: 0;
}

.order-products ul li {
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  /* Основний контейнер сторінки */
  .account-container {
    flex-direction: column;
    margin: 10px;
  }

  /* Горизонтальне меню для мобільних пристроїв */
  .menu-column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #ddd; /* Лінія під меню */
  }

  nav ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  nav ul li {
    flex: 1;
    margin: 0 5px;
    height: auto;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: MaisonNeue;
    text-transform: uppercase;
    font-weight: 900;
    background: #fff;
    border: 1px solid #000;
    color: #000;
    letter-spacing: 1px;
    text-align: center;
    border-radius: 0;
    padding: 10px 0;
  }

  nav ul li.active {
    background-color: #000;
    color: #fff;
  }

  .logout-button {
    width: 100%;
    margin-top: 10px;
    height: 42px;
    background: #fff;
    border: 1px solid #000;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .logout-button:hover {
    background-color: #000;
    color: #fff;
  }

  .content {
    width: 100%;
  }

  .profile-info-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .orders-section {
    margin-top: 10px;
  }

  .order-summary {
    flex-direction: column;
    text-align: left;
  }

  .order-summary span {
    margin-bottom: 5px;
  }

  .order-products {
    margin-top: 5px;
    padding: 5px;
  }

  .order-products h3 {
    font-size: 1.2rem;
  }
}

</style>
